import { useEffect, useState } from "react";
import Close from "../../../icons/SvgClose";
import Button from "../../../uikit/Button";
import "./ProfileSummaryRight.css";

export default function ProfileSummaryRight({
  total,
  data,
  addonData,
  isAddon,
  handelCheckout,
  setcoupon,
  coupon,
  error,
  seterror,
  apply,
  promosuccess,
  closeMessage,
  couponloader,
  discount,
}: any): JSX.Element {
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  const [addonTitleFirst, setAddonTitleFirst] = useState("");
  const [addonTitleSecond, setAddonTitleSecond] = useState("");
  const couponchange = (e: any) => {
    var value = e.target.value.trim();
    if (value.length === 0) {
      setcoupon(e.target.value.trim());
    } else {
      setcoupon(e.target.value);
    }
    seterror("");
  };

  useEffect(() => {
    if (addonData?.title) {
      const titleParts = addonData.title.split(" - ");
      setAddonTitleFirst(titleParts[0] || "");
      setAddonTitleSecond(titleParts[1] || "");
    }
  }, [addonData?.title]);

  return (
    <>
      <div className="card" style={{ minWidth: "315px", height: "auto" }}>
        {couponloader === true && (
          <div className="loader-overlay">
            <div className="spinner-border" role="status">
              <span className="visually-hidden"></span>
            </div>
          </div>
        )}
        <div className="card-body">
          <h5
            style={{
              fontSize: "13px",
              fontWeight: 700,
              marginBottom: "8px",
            }}
          >
            Order Summary
          </h5>
          <hr style={{ margin: "0rem 0rem 1rem" }} />
          {/* <p style={{ fontWeight: 700, fontSize: "20px" }}>${total}</p> */}
          {(data?.colour === "grey" || data?.colour === "red") && (
            <div>
              <div className="d-block text-start">
                <p
                  style={{
                    color: "#333333",
                    fontSize: "13px",
                    fontWeight: 700,
                  }}
                >
                  {`${data?.name} (Monthly)`}
                </p>
                <div
                  className="d-flex justify-content-between"
                  style={{
                    color: "#333333",
                    fontSize: "13px",
                    fontWeight: 400,
                  }}
                >
                  <span>{`${data?.monthly?.requests} Credits `}</span>
                  <span
                    style={{ fontSize: "13px", fontWeight: 700 }}
                  >{`$${data?.monthly.price}`}</span>
                </div>
              </div>
            </div>
          )}
          {isAddon && (
            <>
              <div className="d-block text-start" style={{ marginTop: "20px" }}>
                <p
                  style={{
                    color: "#333333",
                    fontSize: "13px",
                    fontWeight: 700,
                    marginBottom: "0px",
                  }}
                >
                  {addonTitleFirst}
                </p>
                <p
                  style={{
                    color: "#333333",
                    fontSize: "13px",
                    fontWeight: 700,
                  }}
                >
                  {addonTitleSecond}
                </p>
                <div
                  className="d-flex justify-content-between"
                  style={{
                    color: "#333333",
                    fontSize: "13px",
                    fontWeight: 400,
                  }}
                >
                  <span>{`${addonData?.requests} Credits`}</span>
                  <span
                    style={{ fontSize: "13px", fontWeight: 700 }}
                  >{`$${addonData?.addonTotal}`}</span>
                </div>
              </div>
            </>
          )}

          {promosuccess === true ? (
            <>
              <div
                className="d-flex justify-content-between"
                style={{
                  color: "#333333",
                  fontSize: "13px",
                  fontWeight: 400,
                  marginTop: "10px",
                }}
              >
                <span>{coupon}</span>
                <span
                  style={{ fontSize: "13px", fontWeight: 700 }}>
                  {`- $${discount.toString().includes('.') ? discount + '0' : discount}`}
                </span>
              </div>
            </>
          ) : (
            <>
              <hr />
              <div className="input-group ">
                <input
                  type="text"
                  className="form-control"
                  style={{ fontSize: "13px", fontWeight: 400 }}
                  placeholder="Enter your coupon code"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onChange={(e) => couponchange(e)}
                  value={coupon}
                />
                <span
                  className="input-group-text"
                  id="basic-addon2"
                  style={{
                    cursor: "pointer",
                    backgroundColor: "white",
                    fontSize: "13px",
                    fontWeight: 700,
                    color: "#581845",

                    border: isFocused
                      ? "1px solid #581845"
                      : "1px solid #dee2e6", // Border color changes on focus
                    transition: "border 0.3s ease", // Smooth transition when focusing/unfocusing
                  }}
                  onClick={() => apply()}
                >
                  Apply
                </span>
              </div>
            </>
          )}

          <div
            style={{
              fontSize: "13px",
              color: "red",
              textAlign: "left",
              marginTop: "5px",
            }}
          >
            {" "}
            {error}
          </div>
          <hr />
          {promosuccess && (
            <div className="promoMessage">
              <span>{`"${coupon}" code applied.`}</span>
              <span className="closeIcon" onClick={() => closeMessage()}>
                <Close />
              </span>
            </div>
          )}

          <div
            className="d-flex justify-content-between"
            style={{
              color: "#333333",
              fontSize: "13px",
              fontWeight: 700,
            }}
          >
            <span>Total Payable</span>
            <span>
              {total.toString().includes('.')
                ? `$${total}0`
                : `$${total}.00`}
            </span>
          </div>
          <hr />
          <div className="d-flex justify-content-center">
            <Button
              type="submit"
              name="Proceed to Pay"
              width="119px"
              onClick={handelCheckout}
              disabled={error} 
            />
          </div>
        </div>
      </div>
    </>
  );
}
