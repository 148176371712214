import SvgApiProductsCheck from "../../../icons/SvgApiProductsCheck";

export default function ProfileSummaryCard({ data }: any): JSX.Element {
  const billIssuedOn = () => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, "0");
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const year = now.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const nextBilling = () => {
    const date = new Date();

    // Get current date components
    const currentDay = date.getDate();

    // Add one month
    date.setMonth(date.getMonth() + 1);

    // Handle cases where the next month has fewer days (e.g., moving from Jan 31 to Feb)
    if (date.getDate() !== currentDay) {
      date.setDate(0); // Set to the last valid day of the new month
    }

    // Format the date as DD-MM-YYYY
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <>
      <div className="col-md-12">
        <div className="card mb-3 py-3">
          <div
            className="mx-3"
            style={{
              fontWeight: 700,
              fontSize: "14px",
              textAlign: "left",
            }}
          >
            <div className="d-flex justify-content-between">
              <div>
                <SvgApiProductsCheck />
                <span style={{ marginLeft: "8px" }}>API Product Details</span>
              </div>
              {data?.billing_no && (
                <div>
                  <span style={{ color: "#581845", fontWeight: 300 }}>
                    {" "}
                    Billing ID :{" "}
                  </span>
                  <span>{data?.billing_no}</span>
                </div>
              )}
            </div>
          </div>
          <div className="ps-5" style={{ marginTop: "10px" }}>
            <div className="row row-cols-4 g-0">
              <div className="col text-start">
                <div style={{ fontSize: "13px", fontWeight: 700 }}>Title</div>
                <div
                  style={{
                    fontSize: "13px",
                    marginTop: "4px",
                  }}
                >
                  {data?.name}
                </div>
              </div>
              <div className="col text-start" style={{ paddingLeft: "10px" }}>
                <div style={{ fontSize: "13px", fontWeight: 700 }}>
                  No.of.Requests
                </div>
                <div
                  style={{
                    fontSize: "13px",
                    marginTop: "4px",
                  }}
                >
                  {data?.monthly.requests}
                </div>
              </div>
              <div className="col text-start" style={{ paddingLeft: "10px" }}>
                <div style={{ fontSize: "13px", fontWeight: 700 }}>Credits</div>
                <div
                  style={{
                    fontSize: "13px",
                    marginTop: "4px",
                  }}
                >
                  {data?.monthly.requests}
                </div>
              </div>
              <div className="col text-start" style={{ paddingLeft: "10px" }}>
                <div style={{ fontSize: "13px", fontWeight: 700 }}>Amount</div>
                <div
                  style={{
                    fontSize: "13px",
                    marginTop: "4px",
                  }}
                >
                  ${data?.monthly.price}
                </div>
              </div>
              <div
                className="col text-start"
                style={{
                  marginTop: "30px",
                  marginBottom: "25px",
                }}
              >
                <div style={{ fontSize: "13px", fontWeight: 700 }}>
                  Billing Cycle
                </div>
                <div
                  style={{
                    fontSize: "13px",
                    marginTop: "4px",
                  }}
                >
                  {data?.monthly.billing_cycle}
                </div>
              </div>
              <div
                className="col text-start"
                style={{
                  marginTop: "30px",
                  marginBottom: "25px",
                  paddingLeft: "10px",
                }}
              >
                <div style={{ fontSize: "13px", fontWeight: 700 }}>
                  Bill Issued on
                </div>
                <div
                  style={{
                    fontSize: "13px",
                    marginTop: "4px",
                  }}
                >
                  {billIssuedOn()}
                </div>
              </div>
              <div
                className="col text-start"
                style={{
                  marginTop: "30px",
                  marginBottom: "25px",
                  paddingLeft: "10px",
                }}
              >
                <div style={{ fontSize: "13px", fontWeight: 700 }}>
                  Next Due Date
                </div>
                <div
                  style={{
                    fontSize: "13px",
                    marginTop: "4px",
                  }}
                >
                  {nextBilling()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
