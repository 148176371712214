import axios from "axios";

export const loginapi = axios.create({
  baseURL: "https://deuxwp0q07.execute-api.us-east-1.amazonaws.com/Prod",
});
export const applicationapi = axios.create({
  baseURL: "https://t2j89jppqg.execute-api.us-east-1.amazonaws.com/Prod",
});

export const Signin = (username: string, password: string) => {
  const url = "/signin_api";
  return loginapi.get(url, {
    params: {
      username,
      password,
    },
  });
};

export const ForgotPasswordapi = (email: string) => {
  const url = "/send_otp";
  return loginapi.get(url, {
    params: {
      email,
    },
  });
};
export const VerifySignupapi = (email: string, verificationCode: string) => {
  const url = "/verify_signup";
  return loginapi.get(url, {
    params: {
      email,
      verificationCode,
    },
  });
};

export const Signupapi = (
  firstName: string,
  lastName: string,
  password: string,
  phoneNumber: string,
  orgName: string,
  email: string
) => {
  const url = "/signup_api";
  return loginapi.post(url, {
    firstName,
    lastName,
    password,
    phoneNumber,
    orgName,
    email,
  });
};

export const ResendCode = (email: string) => {
  const url = "/resend_code";
  return loginapi.get(url, {
    params: {
      email,
    },
  });
};

export const Signoutapi = (email: string) => {
  const url = "/signout_api";
  return loginapi.get(url, {
    params: {
      email,
    },
  });
};

export const ResetPasswordapi = (
  otp: string,
  password: string,
  email: string
) => {
  const url = "/confirm_code_Reset_Pass";
  return loginapi.get(url, {
    params: {
      confirmation_code: otp,
      new_password: password,
      email,
    },
  });
};

export const Getoverview = async (params?: {
  from_date?: string;
  to_date?: string;
  csv_download?: boolean;
}) => {
  const url = "/reports";
  const idToken = sessionStorage.getItem("id_token");

  try {
    const response = await applicationapi.get(url, {
      params: params, // Pass the parameters here
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching overview:", error);
    throw error;
  }
};

export const Analyticsdata = async (params?: {
  api_name?: string;
  from_date?: string;
  to_date?: string;
  csv_download?: boolean;
}) => {
  const url = "/analytics";
  const idToken = sessionStorage.getItem("id_token");
  try {
    const response = await applicationapi.get(url, {
      params: params, // Pass the parameters here
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching overview:", error);
    throw error;
  }
};

export const Notificationapi = (read?: any, notification?: any) => {
  const url = "/get_notification";
  const idToken = sessionStorage.getItem("id_token");

  return applicationapi.get(url, {
    params: {
      mark_as_read: read,
      notification: notification,
    },
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  });
};
export const Documentations = async () => {
  const url = "/apiproducts";
  const idToken = sessionStorage.getItem("id_token");
  try {
    const response = await applicationapi.get(url, {
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching overview:", error);
    throw error;
  }
};
export const BillingAPI = (invoice_id?: string) => {
  const idToken = sessionStorage.getItem("id_token");
  const url = "/invoice";
  const params = {
    invoice_id: invoice_id,
  };
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
    params: params,
  };
  return applicationapi.get(url, config);
};

export const Coupon_code = async (formData: FormData) => {
  const url = "/coupon_validation";
  const idToken = sessionStorage.getItem("id_token");
  try {
    const response = await applicationapi.post(url, formData, {
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("rek", response);
    return response.data;
  } catch (error) {
    console.error("Error validating coupon:", error);
    throw error;
  }
};

const apis = {
  Signin,
  Getoverview,
  Analyticsdata,
  Notificationapi,
  BillingAPI,
  Coupon_code,
};

export default apis;
