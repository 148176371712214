import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../Login/login.module.css";
import SvgZita from "../../icons/SvgZita";
import Button from "../../uikit/Button";

const ResetSuccess = () => {
  const version = process.env.REACT_APP_VERSION;
  const navigate = useNavigate();
  useEffect(() => {
    const adjustHeaderMargin = () => {
      const header = document.querySelector("header");
      if (header) {
        const bookmarkBarHeight = window.outerHeight - window.innerHeight;
        header.style.marginTop = `${bookmarkBarHeight}px`;
      }
    };

    window.addEventListener("load", adjustHeaderMargin);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("load", adjustHeaderMargin);
    };
  }, []);

  return (
    <div
      className={`container-fluid ${styles.bgimage}`}
      style={{
        backgroundImage: `url(/Images/loginbgimage.png)`,
        fontSize: "13px",
        height: window.innerHeight,
      }}
    >
      <div
        className="d-flex justify-content-center"
        style={{ minHeight: "80vh" }}
      >
        <div
          className="text-center"
          style={{ marginTop: "5%", width: "380px" }}
        >
          <SvgZita width={150} height={80} />
          <div style={{ marginTop: "30%" }}>
            <div>
              <h4 className="fw-bold">Password Reset Successfully</h4>
              <p className="text-start" style={{ marginTop: "6%" }}>
                You have successfully reset your password. Please sign in with
                the new credentials.
              </p>
            </div>
          </div>
          <div
            className=" d-flex justify-content-center "
            style={{ marginTop: "10%" }}
          >
            <Button
              name="Back to Sign in"
              variant="primary"
              height="35px"
              width="35%"
              onClick={() => {
                navigate("/");
              }}
            />
          </div>
        </div>
      </div>
      <div>
        <footer className={`text-center ${styles.rights}`}>
          <div>App Version {version}</div>
          <div>© Sense7ai {new Date().getFullYear()} . ALL RIGHTS RESERVED</div>
        </footer>
      </div>
    </div>
  );
};

export default ResetSuccess;
