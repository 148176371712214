import React, { useState, useEffect } from "react";
import styles from "../Login/login.module.css";
import SvgZita from "../../icons/SvgZita";
import { useNavigate } from "react-router-dom";
import { ForgotPasswordapi, ResetPasswordapi } from "../../api";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { notify } from "../../uikit/toast";
import CustomTooltip from "../../uikit/CustomTooltip/CustomTooltip";

const ResetPassword = () => {
  const version = process.env.REACT_APP_VERSION;
  const navigate = useNavigate();
  const [code, setcode] = useState<string>("");
  const [submit, setsubmit] = useState<boolean>(false);
  const [loader, setloader] = useState<boolean>(false);
  const [codeerrortext, setcodeerrortext] = useState<string>("");
  const [pwderrortext, setpwderrortext] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [cnfpwderrortext, setcnfpwderrortext] = useState<string>("");
  const [cnfpassword, setcnfPassword] = useState<string>("");
  const email = sessionStorage.getItem("email") || "";
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [cnfshowPassword, setcnfShowPassword] = useState<boolean>(false);
  const [timeLeft, setTimeLeft] = useState<number>(60);
  const [isExpired, setIsExpired] = useState<boolean>(false);
  const [icon1, seticon1] = useState<boolean>(false);
  const [icon2, seticon2] = useState<boolean>(false);

  useEffect(() => {
    if (timeLeft <= 0) {
      setIsExpired(true);
      return;
    }

    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const codehandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    setcode(value);
    if (value.length !== 6 && value.length !== 0) {
      setcodeerrortext("Verification code is invalid.");
    } else if (value.length === 0) {
      setcodeerrortext("Please enter verification code.");
    } else {
      setcodeerrortext("");
    }
  };

  const passwordhandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    seticon1(true);
    const value = e.target.value.trim();
    // console.log("error", pwderrortext);
    setPassword(value);
    if (value.length >= 50) {
      setpwderrortext("Password must not exceed 50 characters.");
    } else if (value.length <= 7 && value.length !== 0) {
      setpwderrortext("Password must be at least 8 characters.");
    } else if (value.length === 0) {
      setpwderrortext("Please enter your new password.");
      seticon1(false);
    } else if (!/[A-Z]/.test(value)) {
      setpwderrortext("Password must include at least 1 uppercase letter.");
    } else if (!/\d/.test(value)) {
      setpwderrortext("Password must include at least 1 number.");
    } else if (!/[!@#$%^&*]/.test(value)) {
      setpwderrortext("Password must include at least 1 special character.");
    } else {
      setpwderrortext("");
    }
  };

  const cnfpasswordhandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    seticon2(true);
    const value = e.target.value.trim();
    // console.log("error", pwderrortext);
    setcnfPassword(value);
    if (value.length >= 50) {
      setcnfpwderrortext("Password must not exceed 50 characters.");
    } else if (value.length <= 7 && value.length !== 0) {
      setcnfpwderrortext("Password must be at least 8 characters.");
    } else if (value.length === 0) {
      setcnfpwderrortext("Please confirm your new password.");
      seticon2(false);
    } else {
      setcnfpwderrortext("");
    }
  };

  const handlesubmit = () => {
    setsubmit(true);
    if (code.length === 0) {
      setcodeerrortext("Please enter verification code.");
    }
    if (password.length === 0) {
      setpwderrortext("Please enter your new password.");
    }
    if (cnfpassword.length === 0) {
      setcnfpwderrortext("Please confirm your new password.");
    } else if (password !== cnfpassword) {
      setcnfpwderrortext("The new password and confirm password do not match.");
    } else if (password.length >= 8 && cnfpassword.length >= 8) {
      setcnfpwderrortext("");
    }
    if (
      code.length === 6 &&
      password === cnfpassword &&
      cnfpwderrortext === "" &&
      pwderrortext === "" &&
      password.length !== 0 &&
      cnfpassword.length !== 0
    ) {
      setloader(true);
      ResetPasswordapi(code, cnfpassword, email)
        .then((res) => {
          if (res?.data?.data?.success === false) {
            setcodeerrortext(res.data.data.message);
          } else {
            navigate("/reset-success");
          }
          setloader(false);
        })
        .catch((error) => {
          // console.log("error", error.response.data.data.message);
          setcodeerrortext(error.response.data.data.message);
          setloader(false);
        });
    }
  };

  const resendotp = () => {
    setTimeLeft(60);
    setIsExpired(false);
    ForgotPasswordapi(email)
      .then((res) => {
        notify("Verification code resend successfully.", "success", 3000);
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const cnftogglePasswordVisibility = () => {
    setcnfShowPassword(!cnfshowPassword);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handlesubmit();
    }
  };

  return (
    <div
      className={`container-fluid ${styles.bgimage}`}
      style={{
        backgroundImage: `url(/Images/loginbgimage.png)`,
        fontSize: "13px",
        height: window.innerHeight,
      }}
    >
      <div
        className="d-flex justify-content-center"
        style={{ minHeight: "80vh" }}
      >
        <div
          className="text-center"
          style={{ marginTop: "3%", width: "380px" }}
        >
          <SvgZita width={150} height={80} />
          <div style={{ marginTop: "5%" }}>
            <div>
              <h4 className="fw-bold">Reset your password</h4>
              <p>Please set the new password for your account</p>
            </div>
            <div
              //   style={{ marginTop: "15%" }}
              className="text-start"
            >
              <div>
                <label
                  htmlFor="verificationCode"
                  className="form-label"
                  style={{ fontWeight: "600" }}
                >
                  Verification Code
                </label>
                <input
                  style={{
                    height: "44px",
                    maxWidth: "600px",
                  }}
                  type="text"
                  className={`form-control ${styles.textbox}`}
                  id="verificationCode"
                  name="verificationCode"
                  value={code}
                  onChange={codehandleChange}
                  onKeyDown={handleKeyDown} // Added keydown event
                />
                <div
                  className="row"
                  style={
                    submit && codeerrortext
                      ? { marginBottom: "10px" }
                      : undefined
                  }
                >
                  <div className="col-md-7  col-7">
                    {submit && codeerrortext && (
                      <div
                        style={{
                          color: "red",
                          paddingLeft: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {codeerrortext}
                      </div>
                    )}
                  </div>
                  <div className="col-md-5  col-5 text-end">
                    {isExpired ? (
                      <div
                        onClick={resendotp}
                        className="fw-bold "
                        style={{
                          color: "#581845",
                          textDecoration: "none",
                          paddingRight: "7px",
                          cursor: "pointer",
                        }}
                      >
                        Resend Code
                      </div>
                    ) : (
                      <div style={{ marginRight: "5px" }}>
                        Resend Code in {timeLeft}sec
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="password"
                  className="form-label col-md-6 "
                  style={{ fontWeight: "600" }}
                >
                  New Password
                </label>
                <div className="d-flex align-items-center justify-content-end">
                  <input
                    style={{
                      height: "44px",
                      maxWidth: "600px",
                      paddingRight: "40px",
                    }}
                    type={!showPassword ? "password" : "text"}
                    className={`form-control ${styles.textbox}`}
                    id="password"
                    name="password"
                    value={password}
                    onChange={passwordhandleChange}
                    onKeyDown={handleKeyDown} // Added keydown event
                  />
                  <button
                    type="button"
                    className={styles.passwordToggle}
                    onClick={togglePasswordVisibility}
                    tabIndex={-1}
                  >
                    {icon1 &&
                      (showPassword ? (
                        <CustomTooltip title={"Hide Password"}>
                          <FaEyeSlash color="rgb(88, 24, 69)" />
                        </CustomTooltip>
                      ) : (
                        <CustomTooltip title={"Show Password"}>
                          <FaEye color="rgb(88, 24, 69)" />
                        </CustomTooltip>
                      ))}
                  </button>
                </div>

                {submit && pwderrortext && (
                  <div
                    style={{
                      color: "red",
                      paddingLeft: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {pwderrortext}
                  </div>
                )}
              </div>
              <div className="mb-3 ">
                <label
                  htmlFor="password"
                  className="form-label col-md-6 "
                  style={{ fontWeight: "600" }}
                >
                  Confirm New Password
                </label>
                <div className="d-flex align-items-center justify-content-end">
                  <input
                    style={{
                      height: "44px",
                      maxWidth: "600px",
                      paddingRight: "40px",
                    }}
                    type={!cnfshowPassword ? "password" : "text"}
                    className={`form-control ${styles.textbox}`}
                    id="conformpassword"
                    name="conformpassword"
                    value={cnfpassword}
                    onChange={cnfpasswordhandleChange}
                    onKeyDown={handleKeyDown} // Added keydown event
                  />
                  <button
                    type="button"
                    className={styles.passwordToggle}
                    onClick={cnftogglePasswordVisibility}
                    tabIndex={-1}
                  >
                    {icon2 &&
                      (cnfshowPassword ? (
                        <CustomTooltip title={"Hide Password"}>
                          <FaEyeSlash color="rgb(88, 24, 69)" />
                        </CustomTooltip>
                      ) : (
                        <CustomTooltip title={"Show Password"}>
                          <FaEye color="rgb(88, 24, 69)" />
                        </CustomTooltip>
                      ))}
                  </button>
                </div>
                {submit && cnfpwderrortext && (
                  <div
                    style={{
                      color: "red",
                      paddingLeft: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {cnfpwderrortext}
                  </div>
                )}
              </div>

              <div>
                {loader ? (
                  <button
                    className={`btn ${styles.loginbtnloder}`}
                    type="button"
                  >
                    <span
                      className={`spinner-border spinner-border-sm ${styles.loderboder}`}
                      role="status"
                      style={{ width: "15px", height: "15px" }}
                    ></span>
                  </button>
                ) : (
                  <button
                    className={`btn ${styles.loginbtn}`}
                    type="button"
                    onClick={() => handlesubmit()}
                  >
                    Reset
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <footer className={`text-center ${styles.rights}`}>
          <div>App Version {version}</div>
          <div>© Sense7ai {new Date().getFullYear()} . ALL RIGHTS RESERVED</div>
        </footer>
      </div>
    </div>
  );
};

export default ResetPassword;
